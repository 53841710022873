.header {
    background-color: #333;
    width: 100%;
}

.headerContent {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.logo {
    max-height: 50px;
    max-width: 50px;
}

.navigation {
    display: flex;
    align-items: center;
}

.link {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    margin-left: 20px;
}

.activeLink {
    color: #ff5722; /* Orange color for active link */
    font-weight: bold;
    border-bottom: 2px solid #ff5722; /* Underline effect for active link */
}