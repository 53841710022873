.footer {
    background-color: #333;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.footerContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 14px;
    text-align: center;
}

.footerLinks {
    display: flex;
    gap: 20px;
}

.footerLink {
    color: #fff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.footerCopy {
    margin-top: 20px;
}